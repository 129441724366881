import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { KeywordList, PostImage, PostMeta } from "..";

import { blogPost } from "../article-post/article-post.module.scss";

export function LessonPost({ lesson }: { lesson: any }) {
  const {
    title,
    date,
    category,
    categorySlug,
    author,
    banner,
    keywords,
    bannerAlt,
  } = lesson.frontmatter;

  const featuredImage =
    banner && banner.childImageSharp
      ? banner.childImageSharp.gatsbyImageData
      : null;

  return (
    <div className={blogPost}>
      <PostMeta
        date={date}
        category={category}
        categorySlug={categorySlug}
        author={author}
      />
      {featuredImage && <PostImage image={featuredImage} caption={bannerAlt} />}
      <h1 className="page-title">{title}</h1>
      <div className="blog-post-content">
        <MDXRenderer>{lesson.body}</MDXRenderer>
      </div>
      {keywords && <KeywordList keywords={keywords} />}
    </div>
  );
}
