import React from "react";
import { Lesson } from "../../utils/interfaces";

import {
  courseLessons,
  lessonsList,
  complete,
  active,
  moduleNavHeading,
} from "./lesson-nav.module.scss";

export function LessonNav({
  lessons,
  onSetCurrent,
  current,
}: {
  lessons: Lesson[];
  onSetCurrent: ({ lesson }: { lesson: number }) => void;
  current: number;
}) {
  return (
    <nav className={courseLessons}>
      <h3 className={moduleNavHeading}>Lessons</h3>
      <ul className={lessonsList}>
        {lessons.map((lesson, lessonNum) => {
          let currentClass;

          if (lessonNum === current) {
            currentClass = active;
          } else if (lesson.complete === true) {
            currentClass = complete;
          }

          return (
            <li key={lesson.slug}>
              <button
                className={currentClass}
                onClick={() => onSetCurrent({ lesson: lessonNum })}
              >
                {lessonNum + 1}. {lesson.title}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
