import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  buttonComplete,
  buttonCompleteIcon,
} from "./button-complete.module.scss";

library.add(far);
library.add(fas);

export function ButtonComplete({
  status,
  onComplete,
}: {
  status: boolean;
  onComplete: (status: boolean) => void;
}) {
  if (status) {
    return (
      <button className={buttonComplete} onClick={() => onComplete(false)}>
        <span className={buttonCompleteIcon}>
          <FontAwesomeIcon icon={["fas", "check-circle"]} />
        </span>{" "}
        Complete
      </button>
    );
  } else {
    return (
      <button className={buttonComplete} onClick={() => onComplete(true)}>
        <span className={buttonCompleteIcon}>
          <FontAwesomeIcon icon={["far", "check-circle"]} />
        </span>{" "}
        Mark Complete
      </button>
    );
  }
}
