import {
  Article,
  ArticleRaw,
  // Category,
  Course,
  CourseRaw,
  Lesson,
  LessonRaw,
  Module,
  ModuleRaw,
} from "./interfaces";

export const DAYS = [
  {
    name: "Sunday",
    shortName: "Sun",
  },
  {
    name: "Monday",
    shortName: "Mon",
  },
  {
    name: "Tuesday",
    shortName: "Tues",
  },
  {
    name: "Wednesday",
    shortName: "Wed",
  },
  {
    name: "Thursday",
    shortName: "Thur",
  },
  {
    name: "Friday",
    shortName: "Fri",
  },
  {
    name: "Saturday",
    shortName: "Sat",
  },
];

export const MONTHS = [
  {
    name: "January",
    shortName: "Jan",
    numDays: 31,
  },
  {
    name: "February",
    shortName: "Feb",
    numDays: 28,
  },
  {
    name: "March",
    shortName: "Mar",
    numDays: 31,
  },
  {
    name: "April",
    shortName: "Apr",
    numDays: 30,
  },
  {
    name: "May",
    shortName: "May",
    numDays: 31,
  },
  {
    name: "June",
    shortName: "Jun",
    numDays: 30,
  },
  {
    name: "July",
    shortName: "Jul",
    numDays: 31,
  },
  {
    name: "August",
    shortName: "Aug",
    numDays: 31,
  },
  {
    name: "September",
    shortName: "Sep",
    numDays: 30,
  },
  {
    name: "October",
    shortName: "Oct",
    numDays: 31,
  },
  {
    name: "November",
    shortName: "Nov",
    numDays: 30,
  },
  {
    name: "December",
    shortName: "Dec",
    numDays: 31,
  },
];

export const states = [
  "Alabama",
  "Alaska",
  "Arkansas",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "Nevada",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export function fullYear(date: Date) {
  return date.getFullYear();
}

export function titleCase(str: string) {
  try {
    return str.replace(
      /[\w-]\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  } catch (error) {
    throw new Error("please provide a valid string");
  }
}

export function normalizeLessons(lessons: LessonRaw[]) {
  if (lessons) {
    return lessons.map(normalizeLesson);
  }

  console.error("no lessons");
  return [];
}

export function normalizeLesson(lesson: LessonRaw): Lesson {
  return {
    id: lesson.id,
    body: lesson.childMdx.body,
    slug: lesson.childMdx.frontmatter.slug,
    title: lesson.childMdx.frontmatter.title,
    parentCourse: lesson.childMdx.frontmatter.parentCourse,
    parentModule: lesson.childMdx.frontmatter.parentModule,
    order: lesson.childMdx.frontmatter.order,
    complete: false,
    description: lesson.childMdx.frontmatter.description,
    banner: lesson.childMdx.frontmatter.banner,
    bannerAlt: lesson.childMdx.frontmatter.bannerAlt,
    category: lesson.childMdx.frontmatter.category,
    categorySlug: lesson.childMdx.frontmatter.categorySlug,
    author: lesson.childMdx.frontmatter.author,
    date: lesson.childMdx.frontmatter.date,
    keywords: lesson.childMdx.frontmatter.keywords,
    prevModule: lesson.childMdx.frontmatter.prevModule,
    nextModule: lesson.childMdx.frontmatter.nextModule,
    prev: lesson.childMdx.frontmatter.prev,
    next: lesson.childMdx.frontmatter.next,
  };
}

export function normalizeModules(
  modules: { edges: ModuleRaw[] },
  lessons: { edges: { node: LessonRaw }[] }
) {
  if (!modules || modules?.edges.length === 0) {
    console.error("no modules");
  }

  const withLessons = getModuleLessons(modules, lessons);

  return withLessons ? withLessons.map(normalizeModule) : [];
}

function getModuleLessons(
  modules: { edges: any[] },
  lessons: { edges: { node: LessonRaw }[] }
) {
  return modules.edges.map(({ node }) => {
    const moduleLessons = lessons.edges
      .map(({ node }) => node)
      .filter(
        (lesson) =>
          lesson.childMdx.frontmatter.parentModule ===
          node.childMdx.frontmatter.slug
      );

    return {
      id: node.id,
      frontmatter: node.childMdx.frontmatter,
      lessons: moduleLessons,
    };
  });
}

export function normalizeModule(module: ModuleRaw) {
  return {
    id: module.id,
    slug: module.frontmatter.slug,
    title: module.frontmatter.title,
    metaTitle: module.frontmatter.metaTitle,
    parentCourse: module.frontmatter.parentCourse,
    description: module.frontmatter.description,
    banner: module.frontmatter.banner,
    bannerAlt: module.frontmatter.bannerAlt,
    category: module.frontmatter.category,
    categorySlug: module.frontmatter.categorySlug,
    author: module.frontmatter.author,
    date: module.frontmatter.date,
    keywords: module.frontmatter.keywords,
    order: module.frontmatter.order,
    lessons:
      module.lessons && module.lessons.length
        ? normalizeLessons(module.lessons)
        : [],
  };
}

export function normalizeCourses(courses: CourseRaw[]) {
  if (courses) {
    return courses.map(normalizeCourse);
  }

  console.error("no courses");
  return [];
}

export function normalizeCourse(course: CourseRaw): Course {
  return {
    id: course.id,
    slug: course.childMdx.frontmatter.slug,
    title: course.childMdx.frontmatter.title,
    description: course.childMdx.frontmatter.description,
    banner: course.childMdx.frontmatter.banner,
    bannerAlt: course.childMdx.frontmatter.bannerAlt,
    category: course.childMdx.frontmatter.category,
    categorySlug: course.childMdx.frontmatter.categorySlug,
    author: course.childMdx.frontmatter.author,
    date: course.childMdx.frontmatter.date,
    keywords: course.childMdx.frontmatter.keywords,
  };
}

export function normalizeArticles(articles: ArticleRaw[]) {
  if (articles) {
    // console.log({ articles });
    return articles.map(normalizeArticle);
  }

  console.error("no articles");
  return [];
}

export function normalizeArticle(article: ArticleRaw): Article {
  if (!article || !article.childMdx) {
    return {
      id: "",
      excerpt: "",
      title: "",
      date: "",
      author: "",
      category: "",
      categorySlug: "",
      slug: "",
      keywords: [],
      banner: null,
      bannerAlt: "",
      body: "",
    };
  }

  return {
    id: article.id,
    excerpt: article.childMdx.excerpt,
    title: article.childMdx.frontmatter.title,
    date: article.childMdx.frontmatter.date,
    author: article.childMdx.frontmatter.author,
    category: article.childMdx.frontmatter.category,
    categorySlug: article.childMdx.frontmatter.categorySlug,
    slug: article.childMdx.frontmatter.slug,
    keywords: article.childMdx.frontmatter.keywords,
    banner: article.childMdx.frontmatter.banner,
    bannerAlt: article.childMdx.frontmatter.bannerAlt,
    body: article.childMdx.body,
  };
}

export function normalizeCategories(
  categories: {
    categorySlug: string;
    nodes: ArticleRaw[];
  }[]
) {
  if (categories) {
    return categories.map((category) => {
      return {
        categorySlug: category.categorySlug,
        category: category.nodes[0].childMdx.frontmatter.category,
        articles: normalizeArticles(category.nodes),
      };
    });
  }

  console.error("no categories");
  return [];
}

export function deslugify(str: string) {
  return str
    .replace(/-/g, " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

export function slugify(str: string) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function getFeaturedImage(module: Module) {
  const featuredImage =
    module.banner?.childImageSharp?.gatsbyImageData ||
    module.lessons[0]?.banner?.childImageSharp?.gatsbyImageData ||
    null;
  const caption =
    module.bannerAlt || module.lessons[0]?.bannerAlt || "this is some alt text";

  return { featuredImage, caption };
}

export function generateFormFieldId(formId: string, field: string): string {
  return `${formId}-${field}`;
}

export function formattedDate(dateObj: {
  month: number;
  date: number;
  year: number;
}) {
  // console.log({ dateObj });
  const { month, date, year } = dateObj;

  if (
    typeof month === "number" &&
    typeof date === "number" &&
    typeof year === "number"
  ) {
    const tmpDay = new Date(year, month, date);
    return `${DAYS[tmpDay.getDay()].name}, ${
      MONTHS[month]?.name
    } ${date}, ${year}`;
  } else {
    return;
  }
}
