import React from "react";

import { postTags, postTag } from "./keyword-list.module.scss";

export function KeywordList({ keywords }: { keywords: string[] }) {
  return (
    <div className={postTags}>
      <h3>Tags</h3>
      {keywords.map((keyword) => (
        <div className={postTag} key={keyword}>
          {keyword}
        </div>
      ))}
    </div>
  );
}
