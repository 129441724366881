import React from "react";
import { Link } from "gatsby";

export function CallToAction() {
  return (
    <div className="call-to-action">
      <h3>Need Help with Your Website</h3>
      <p>
        If you need help with your website contact me{" "}
        <Link to="/contact">here</Link>.
      </p>
    </div>
  );
}
