import { useStaticQuery, graphql } from "gatsby";
import { ArticleRaw } from "../utils/interfaces";
import { normalizeArticles } from "../utils/utils";

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

const getArticles = graphql`
  query {
    published: allFile(
      filter: {
        sourceInstanceName: { eq: "articles" }
        childMdx: { frontmatter: { status: { eq: "published" } } }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          childMdx {
            excerpt(pruneLength: 300)
            frontmatter {
              title
              date
              author
              category
              categorySlug
              slug
              keywords
              bannerAlt
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    all: allFile(
      filter: { sourceInstanceName: { eq: "articles" } }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          childMdx {
            excerpt(pruneLength: 300)
            frontmatter {
              title
              date
              author
              category
              categorySlug
              slug
              keywords
              bannerAlt
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useArticles = () => {
  const { all, published } = useStaticQuery(getArticles);
  const articles: ArticleRaw[] =
    activeEnv === "development"
      ? all.edges.map(({ node }: { node: any }) => node)
      : published.edges.map(({ node }: { node: any }) => node);

  console.log({ articles: published });
  return normalizeArticles(articles);
};
