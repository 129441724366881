import React from "react";
import { Link } from "gatsby";
import { formattedDate } from "../../utils/utils";

import { postMeta } from "./post-meta.module.scss";

export function PostMeta({
  date: postDate,
  category,
  categorySlug,
  author,
}: {
  date: string;
  category: string;
  categorySlug: string;
  author: string;
}) {
  const [year, month, date]: any = postDate.split("-").map((o) => {
    return +o.replace(/^0/, "");
  });

  return (
    <dl className={postMeta}>
      <div>
        <dt>Published On:</dt>
        <dd>{formattedDate({ month: month - 1, date, year })}</dd>
      </div>
      <div>
        <dt>Category:</dt>
        <dd>
          <Link to={`/category/${categorySlug}`}>{category}</Link>
        </dd>
      </div>
      <div>
        <dt>Author:</dt> <dd>{author}</dd>
      </div>
    </dl>
  );
}
