import React from "react";
import { Link } from "gatsby";
import { relatedPosts } from "./related-posts.module.scss";

export function RelatedPosts({ posts }: { posts: any[] }) {
  return (
    <div className={relatedPosts}>
      <h3>You May Also Like:</h3>
      <ul>
        {posts.map(({ node }: { node: any }) => (
          <li key={node.childMdx.frontmatter.slug}>
            <Link to={`/article/${node.childMdx.frontmatter.slug}`}>
              {node.childMdx.frontmatter.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
