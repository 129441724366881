/**
 * SeoHeaders component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// import path from 'path'
import React from "react";
import Helmet from "react-helmet";
import { useSiteMetadata } from "../../hooks/use-site-metadata";
import SchemaOrg from "../schema-org/schema-org";
import { IGatsbyImageData } from "gatsby-plugin-image";

function SeoHeaders({
  siteMetadata: seo,
  lang = "en",
  isBlogPost = false,
  frontmatter: postMeta = {},
  title,
  url,
  image = isBlogPost ? postMeta?.banner?.childImageSharp?.fluid?.src : "",
  datePublished = isBlogPost ? postMeta.date : "false",
}: {
  siteMetadata: {
    description: string;
    title: string;
    social: { twitter: string };
    canonicalUrl: string;
    organization: {
      name: string;
      url: string;
      logo: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
    };
    author: { name: string };
  };
  lang: string;
  isBlogPost: boolean;
  frontmatter: any;
  title: string;
  url: string;
  image: any;
  datePublished: string;
}) {
  const metaTitle = postMeta.metaTitle || title;
  const metaDescription = postMeta.metaDescription || seo.description;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={`%s | ${seo.title}`}
      >
        <meta name="description" content={metaDescription} />
        <meta name="image" content={image} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.social.twitter} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={metaTitle}
        image={image}
        description={metaDescription}
        datePublished={datePublished}
        canonicalUrl={seo.canonicalUrl}
        author={seo.author}
        organization={seo.organization}
        defaultTitle={seo.title}
      />
    </>
  );
}

function SeoHeadersWithQuery(props: any) {
  const siteMetadata = useSiteMetadata();

  return <SeoHeaders siteMetadata={siteMetadata} {...props} />;
}

export default SeoHeadersWithQuery;
