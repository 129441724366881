import React from "react";

import { shadowBox, boxHeader, boxHolder } from "./box-holder.module.scss";

export function BoxHolder({
  heading,
  children,
}: {
  heading: string;
  children: React.ReactNode;
}) {
  return (
    <div className={shadowBox}>
      <header className={boxHeader}>
        <h2>{heading}</h2>
      </header>
      <section className={boxHolder}>{children}</section>
    </div>
  );
}
