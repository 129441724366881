import React from "react";
import { Link } from "gatsby";

import { KeywordList, PostImage, PostMeta } from "..";
import { Article } from "../../utils/interfaces";

export function PostTeaser({ article }: { article: Article }) {
  const featuredImage =
    article.banner && article.banner.childImageSharp
      ? article.banner.childImageSharp.gatsbyImageData
      : null;

  return (
    <div className="post-teaser">
      {featuredImage && (
        <Link to={`/article/${article.slug}`}>
          <PostImage image={featuredImage} caption={article.bannerAlt} />
        </Link>
      )}
      <h2 className="post-teaser-title">
        <Link to={`/article/${article.slug}`}>{article.title}</Link>
      </h2>
      <PostMeta
        date={article.date}
        category={article.category}
        categorySlug={article.categorySlug}
        author={article.author}
      />
      <p className="post-teaser-excerpt">{article.excerpt}</p>
      <Link to={`/article/${article.slug}`} className="read-link">
        Read More
      </Link>
      {article.keywords && <KeywordList keywords={article.keywords} />}
    </div>
  );
}
