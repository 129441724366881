import React from "react";
import { useSiteMetadata } from "../../hooks/use-site-metadata";

import { footerMain } from "./footer-main.module.scss";

export function FooterMain() {
  const { author }: { author: { name: string } } = useSiteMetadata();

  return (
    <footer className={footerMain}>
      &copy; {new Date().getFullYear()}, {author.name} All Rights Reserved
    </footer>
  );
}
