import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { postTeaserImage } from "./post-image.module.scss";

export function PostImage({ image, caption }: { image: any; caption: string }) {
  const featuredImage = getImage(image);
  // console.log({ featuredImage });

  return featuredImage ? (
    <figure className={postTeaserImage}>
      <GatsbyImage image={featuredImage} alt={caption || ""} />
    </figure>
  ) : (
    <p>Missing Image</p>
  );
}
