import { Link } from "gatsby";
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSiteMetadata } from "../../hooks/use-site-metadata";
const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

import {
  headerMain,
  headerInner,
  logo,
  navHolder,
  menuItem,
} from "./header-main.module.scss";

library.add(fab);

export function HeaderMain() {
  const data = useSiteMetadata();

  const siteTitle = data.title;
  const twitter = data.social.twitter;
  const github = data.social.github;
  const linkedin = data.social.linkedin;

  return (
    <header aria-label="site header" className={headerMain}>
      <div className={headerInner}>
        <p className={`${logo} elr-bold`}>
          <Link to="/" aria-label="navigate to home">
            {siteTitle}
          </Link>
        </p>
        <div className={navHolder}>
          <nav aria-label="Main">
            <ul>
              <li className={menuItem}>
                <Link to="/articles">Articles</Link>
              </li>
              {activeEnv === "development" && (
                <li className={menuItem}>
                  <Link to="/courses">Courses</Link>
                </li>
              )}
              <li className={menuItem}>
                <Link to="/about">About Me</Link>
              </li>
              <li className={menuItem}>
                <Link to="/contact">Contact Me</Link>
              </li>
              {activeEnv === "development" && (
                <li className={menuItem}>
                  <Link to="/projects">Projects</Link>
                </li>
              )}
            </ul>
          </nav>
          <nav aria-label="Social">
            <ul>
              <li className={menuItem}>
                <a title="Twitter" aria-label="twitter" href={twitter}>
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
              </li>
              <li className={menuItem}>
                <a title="GitHub" aria-label="github" href={github}>
                  <FontAwesomeIcon icon={["fab", "github"]} />
                </a>
              </li>
              <li className={menuItem}>
                <a title="LinkedIn" aria-label="linkedin" href={linkedin}>
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
