import { Link } from "gatsby";
import React from "react";
import {
  sidebarMain,
  sidebarInner,
  sidebarHeading,
  sidebarContent,
} from "./sidebar-main.module.scss";

export function SidebarMain({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <aside className={sidebarMain}>
      <div className={`shadow-box ${sidebarInner}`}>
        <h3 className={sidebarHeading}>{title}</h3>
        <div className={sidebarContent}>{children}</div>
        <Link to="/contact" className="elr-button elr-button-primary">
          Hire Me
        </Link>
      </div>
    </aside>
  );
}
