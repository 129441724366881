import { useStaticQuery, graphql } from "gatsby";

const getSiteMetadata = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        title
        description
        canonicalUrl
        image
        author {
          name
          minibio
        }
        social {
          twitterHandle
          twitter
          github
          linkedin
        }
        organization {
          name
          url
          logo
        }
      }
    }
  }
`;

export const useSiteMetadata = () => {
  const {
    site,
  }: {
    site: {
      siteMetadata: {
        author: { name: string };
        title: string;
        description: string;
        canonicalUrl: string;
        image: any;
        social: {
          twitterHandle?: string;
          twitter?: string;
          github?: string;
          linkedin?: string;
        };
        organization: {
          name: string;
          url: string;
          logo: string;
        };
      };
    };
  } = useStaticQuery(getSiteMetadata);

  return site.siteMetadata;
};
