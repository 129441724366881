import React from "react";
import { ArticleTeaser, BoxHolder } from "..";
import { useArticles } from "../../hooks/use-articles";

import { contentBlockHolder } from "./latest-articles.module.scss";

export function LatestArticles() {
  const articles = useArticles();

  return (
    <BoxHolder heading="Articles">
      {articles.slice(0, 12).map((article) => (
        <div className={contentBlockHolder} key={article.id}>
          <ArticleTeaser article={article} />
        </div>
      ))}
    </BoxHolder>
  );
}
