import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { KeywordList, PostImage, PostMeta } from "..";

import { blogPost } from "./article-post.module.scss";
import { Article } from "../../utils/interfaces";

export function ArticlePost({ article }: { article: Article }) {
  const {
    banner,
    title,
    bannerAlt,
    date,
    category,
    categorySlug,
    author,
    keywords,
    body,
  } = article;
  const featuredImage = banner?.childImageSharp?.gatsbyImageData;
  // console.log({ banner });

  return (
    <div className={blogPost}>
      <h1 className="page-title">{title}</h1>
      {featuredImage && <PostImage image={featuredImage} caption={bannerAlt} />}
      <PostMeta
        date={date}
        category={category}
        categorySlug={categorySlug}
        author={author}
      />
      <div className="blog-post-content">
        {body && <MDXRenderer>{body}</MDXRenderer>}
      </div>
      {keywords && <KeywordList keywords={keywords} />}
    </div>
  );
}
