import React from "react";

import { MDXRenderer } from "gatsby-plugin-mdx";

import { ButtonComplete, KeywordList, PostImage, PostPager } from "..";

import {
  courseContent,
  courseHeading,
  courseMeta,
  lessonContent,
} from "./module-lesson.module.scss";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Lesson } from "../../utils/interfaces";

export function ModuleLesson({
  lesson,
  moduleName,
  markComplete,
  lessonImage,
  onPagerClick,
}: {
  lesson: Lesson;
  moduleName: string;
  markComplete: (status: boolean, slug: string) => void;
  lessonImage: IGatsbyImageData;
  onPagerClick: (direction: "prev" | "next") => void;
}) {
  return (
    <div className={courseContent}>
      {lessonImage && (
        <PostImage image={lessonImage} caption={lesson.bannerAlt} />
      )}
      <div className={courseHeading}>
        <h2>{moduleName}</h2>
        <h1 className="page-title">{lesson ? lesson.title : "Coming Soon"}</h1>
        {lesson && (
          <div className={courseMeta}>
            <ButtonComplete
              status={lesson.complete}
              onComplete={() => markComplete(!lesson.complete, lesson.slug)}
            />
          </div>
        )}
      </div>
      {lesson && (
        <>
          <div className={lessonContent}>
            <MDXRenderer>{lesson.body}</MDXRenderer>
          </div>
          <div>
            <PostPager
              onPagerClick={onPagerClick}
              prevTitle={lesson?.prev?.title}
              nextTitle={lesson?.next?.title}
            />
          </div>
          <div>
            {lesson.keywords && <KeywordList keywords={lesson.keywords} />}
          </div>
          <p>{lesson.category}</p>
        </>
      )}
    </div>
  );
}
