import React, { useState } from "react";
import { states } from "../../utils/utils";

import { form, formRow, formGroup } from "./form-contact.module.scss";

export function FormContact() {
  const [formData, updateForm] = useState({
    emailAddress: "",
    companyName: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    phone: "",
    message: "",
    botField: "",
  });

  const encode = (formData: any) => {
    return Object.keys(formData)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
      )
      .join("&");
  };

  const [isSubmitted, updateSubmit] = useState(false);

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    console.log({ formData });

    const form = evt.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        // navigate(form.getAttribute('action'))
        updateSubmit(true);
      })
      .catch((error) => alert(error));
  };

  const handleChange = ({ target }: { target: any }) => {
    const { name, value } = target;

    updateForm({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      {!isSubmitted ? (
        <form
          className={form}
          onSubmit={handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="botField"
          method="post"
          name="contact"
        >
          <div className={formRow}>
            <div className={formGroup}>
              <label htmlFor="firstName">First Name:</label>
              <input type="text" id="firstName" name="firstName" required />
            </div>
            <div className={formGroup}>
              <label htmlFor="lastName">Last Name:</label>
              <input type="text" id="lastName" name="lastName" required />
            </div>
          </div>
          <div className={formRow}>
            <div className={formGroup}>
              <label htmlFor="companyName">Company Name:</label>
              <input
                type="text"
                onChange={handleChange}
                defaultValue={formData.companyName}
                id="companyName"
                name="companyName"
                required
              />
            </div>
          </div>
          <div className={formRow}>
            <div className={formGroup}>
              <label htmlFor="city">City:</label>
              <input
                type="text"
                onChange={handleChange}
                defaultValue={formData.city}
                id="city"
                name="city"
                required
              />
            </div>
            <div className={formGroup}>
              <label htmlFor="state">State:</label>
              <div className="elr-select-wrapper">
                <select
                  name="state"
                  id="state"
                  required
                  onBlur={handleChange}
                  defaultValue={formData.state}
                >
                  <option value="">Choose a State</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={formRow}>
            <div className={formGroup}>
              <label htmlFor="emailAddress">Email Address:</label>
              <input
                type="email"
                onChange={handleChange}
                defaultValue={formData.emailAddress}
                id="emailAddress"
                name="emailAddress"
                required
              />
            </div>
          </div>
          <div className={formRow}>
            <div className={formGroup}>
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                onChange={handleChange}
                defaultValue={formData.phone}
                id="phone"
                name="phone"
                required
              />
            </div>
          </div>
          <div className={formRow}>
            <div className={formGroup}>
              <label htmlFor="message">Message:</label>
              <textarea
                onChange={handleChange}
                defaultValue={formData.message}
                id="message"
                name="message"
                required
              ></textarea>
            </div>
          </div>
          <button type="submit">Send Message</button>
        </form>
      ) : (
        <p className="form-message">Thank you for contacting us!</p>
      )}
    </>
  );
}
