import { Link } from "gatsby";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
  contentBlock,
  contentBlockImage,
  contentBlockHeading,
  // contentBlockMeta,
} from "./article-teaser.module.scss";
import { Article } from "../../utils/interfaces";

export function ArticleTeaser({ article }: { article: Article }) {
  const featuredImage = getImage(
    article?.banner?.childImageSharp?.gatsbyImageData
  );

  return (
    <article className={contentBlock}>
      <Link to={`/article/${article.slug}`}>
        {featuredImage && (
          <figure className={contentBlockImage}>
            <GatsbyImage image={featuredImage} alt="" />
          </figure>
        )}
        <h3 className={contentBlockHeading}>{article.title}</h3>
        {/* <dl className={contentBlockMeta}>
          <div>
            <dt className="elr-bold">Category:</dt>
            <dd>{article.category}</dd>
          </div>
          <div>
            <dt className="elr-bold">Published On:</dt>
            <dd>{article.date}</dd>
          </div>
        </dl> */}
      </Link>
    </article>
  );
}
