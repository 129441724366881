import React from "react";

import {
  postPager,
  pagerLink,
  prevLink,
  nextLink,
} from "./post-pager.module.scss";

export function PostPager({
  onPagerClick,
  prevTitle,
  nextTitle,
}: {
  onPagerClick: (direction: "prev" | "next") => void;
  prevTitle?: string;
  nextTitle?: string;
}) {
  return (
    <div className={postPager}>
      {prevTitle && (
        <button
          className={`${pagerLink} ${prevLink}`}
          onClick={() => onPagerClick("prev")}
        >
          Previous: {prevTitle}
        </button>
      )}
      {nextTitle && (
        <button
          className={`${pagerLink} ${nextLink}`}
          onClick={() => onPagerClick("next")}
        >
          Next: {nextTitle}
        </button>
      )}
    </div>
  );
}
